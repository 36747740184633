import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';

const NetworkSettings = ({ open, onClose, entity }) => {
    const [nfeContent, setNfeContent] = useState('');
    const [nfeTransferPercentage, setNfeTransferPercentage] = useState('');
    const [loading, setLoading] = useState(false); // Estado para controlar o carregamento

    useEffect(() => {
        // Carregar as informações da entidade quando o modal for aberto
        const fetchEntityData = async () => {
            setLoading(true); // Inicia o carregamento
            try {
                if (!entity || !entity.id) {
                    throw new Error('Entidade inválida ou não definida.');
                }

                const response = await window.cs_get(`/entities/${entity.id}`);
                const { nfe_content, nfe_transfer_percentage } = response.data;

                // Preencher os campos com os valores retornados
                setNfeContent(nfe_content || '');
                setNfeTransferPercentage(nfe_transfer_percentage || '');
            } catch (error) {
                toast.error('Erro ao carregar as informações da entidade: ' + error.message);
            } finally {
                setLoading(false); // Finaliza o carregamento
            }
        };

        if (open) {
            fetchEntityData();
        }
    }, [open, entity]);

    const handleSave = async () => {
        try {
            const response = await window.cs_post(`/entities/${entity.id}/updateNetworkSettings`, {
                nfe_content: nfeContent,
                nfe_transfer_percentage: parseFloat(nfeTransferPercentage),
            });

            toast.success('Configurações da rede ' + entity.name + ' salvas com sucesso!');
            onClose();
        } catch (error) {
            toast.error('Erro ao salvar as configurações da rede: ' + error);
        }
    };

    return (
        <Modal open={open} onClose={onClose} size="small">
            <Modal.Header>Configurações da Rede</Modal.Header>
            <Modal.Content>
                {loading ? (
                    <div>Carregando...</div>
                ) : (
                    <Form>
                        <div>
                            <h3>Configurar Nota Fiscal e Repasse</h3>

                            <Form.Field>
                                <label>Modelo da Nota Fiscal</label>
                                <TextArea
                                    value={nfeContent}
                                    onChange={(e) => setNfeContent(e.target.value)}
                                    rows={15}
                                />
                                <small style={{display: 'block', marginTop: 5}}>
                                    <code>
										<span title="Informações de Local" style={{cursor:'pointer'}}>[local_info]</span>,  
										<span title="ID do Pedido" style={{cursor:'pointer'}}>[pedido_id]</span>, 
										<span title="Investimento Total" style={{cursor:'pointer'}}>[investimento_total]</span>, 
										<span title="Informações de Pagamento" style={{cursor:'pointer'}}>[pagamento_info]</span>, 
										<span title="Informações de Repasse" style={{cursor:'pointer'}}>[repasse_info]</span>, 
										<span title="Informações de Parcelas" style={{cursor:'pointer'}}>[parcelas_info]</span>, 
										<span title="Tributos" style={{cursor:'pointer'}}>[tributos]</span>
									</code>
                                </small>
                            </Form.Field>

                            <Form.Field>
                                <label>Percentual de Repasse</label>
                                <Input
                                    type="number"
                                    label="%"
                                    labelPosition="right"
                                    value={nfeTransferPercentage}
                                    onChange={(e) => setNfeTransferPercentage(e.target.value)}
									style={{maxWidth:140}}
                                />
                            </Form.Field>
                        </div>
                    </Form>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button color="green" onClick={handleSave} disabled={loading || !nfeContent || !nfeTransferPercentage}>Salvar Configurações</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default NetworkSettings;