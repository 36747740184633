import React, { Component } from 'react';
import { Grid, Form, Icon, Tab, Menu, Message, Modal, Table, Button, Popup } from 'semantic-ui-react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import localforage from 'localforage';
import moment from 'moment';

import DropzoneComponent from 'react-dropzone-component';

import ContentHeader from '../../components/ContentHeader';
import TitledBlock from '../../components/TitledBlock';
import OrderQuotaEditor from '../../components/OrderQuotaEditor';

import IDs from 'unid-core/lib/utils/IDs';
import { getTheaterById } from 'unid-core/lib/Theaters';
import { plurarify } from 'unid-core/lib/utils/string';

import './ProgrammingAdmin.css';

const ContentDrag = SortableHandle(({ value }) => {
  return <Table.Cell style={{width:100}}>{ value }</Table.Cell>;
});

const ContentItem = SortableElement(({ value, _this, isNew, wasModified, wasRemoved }) => {
  let weeks = moment(value.end_date).diff(moment(), 'weeks') + 1;

  let fgColor = '#000';

  if (isNew) {
    fgColor = '#2180F9';
  } else if (wasModified) {
    fgColor = '#F2B335';
  }

  return (
    <Table.Row style={{textAlign:'center',userSelect:'none',color:fgColor,fontWeight:fgColor!=='#000'?600:400}}>
      <ContentDrag value={value.room} />
      <Table.Cell>{ value.name }</Table.Cell>
      <Table.Cell style={{width:100}}>{ value.name.trim() === 'UNID CINEMÍDIA' ? 'Indeterminado' : moment(value.start_date).format('DD/MM/YY') }</Table.Cell>
      <Table.Cell style={{width:100}}>{ value.name.trim() === 'UNID CINEMÍDIA' ? 'Indeterminado' : moment(value.end_date).format('DD/MM/YY') }</Table.Cell>
      <Table.Cell style={{width:140}}>{ value.name.trim() === 'UNID CINEMÍDIA' ? 'Indeterminado' :  weeks + plurarify(weeks, ' semana', ' semanas') }</Table.Cell>
      <Table.Cell>{ value.ref }</Table.Cell>
      { value.status !== null &&
        <Table.Cell style={{textAlign:'right', width:160}}>
          { value.status === 1 ? <Icon name='check circle' size='large' color='olive' /> : <Icon name='times circle' size='large' color='red' /> }
          { value.info &&
            <Popup inverted position='top right' trigger={<Icon size='large' color='purple' name='exclamation circle' />}>
              { value.info }
            </Popup>
          }
          <Button size='mini' icon='exclamation' onClick={()=>_this.setMediaAttachmentInfo(value.checking_id, value.info )} />
          <Button size='mini' icon='pencil' onClick={()=>_this.uncheckMediaAttachment(value.checking_id)} />
        </Table.Cell>
      }
      { value.status === null &&
        <Table.Cell style={{textAlign:'right', width:160}}>
          <Button size='mini' color='red' icon='delete' onClick={()=>_this.checkMediaAttachment(false, value)} />
          <Button size='mini' color='olive' icon='check' onClick={()=>_this.checkMediaAttachment(true, value)} />
        </Table.Cell>
      }
    </Table.Row>
  );
});

const ContentList = SortableContainer(({ checking, _this }) => {
  let theaterId = checking.length > 0 ? checking[0].theater_id : undefined;
  let lastChecking = theaterId && _this.state.lastChecking[theaterId] ? [ ..._this.state.lastChecking[theaterId] ] : [];

  let notFound = [];
  for (const lc of lastChecking) {
    let found = false;

    for (const c of checking) {
      if (c.order_id === lc.order_id && c.theater_id === lc.theater_id) {
        found = true;
        break;
      }
    }

    if (!found) notFound.push(lc);
  }

  return (
    <Table attached>
      <Table.Body>
        {checking.map((e, i) => {
          let isNew = true;
          let wasModified = undefined;

          for (let lc of lastChecking) {
            if (lc.status === 1 && lc.order_id === e.order_id && lc.theater_id === e.theater_id) {
              isNew = false;
              wasModified = false;

              if (lc.ref !== e.ref) {
                wasModified = true;
              }
            }
          }

          return <ContentItem key={i} index={i} value={e} _this={_this} isNew={isNew} wasModified={wasModified} />;
        })}

        {notFound.map((e, i) => {
          return (
            <Table.Row key={i} style={{textAlign:'center',userSelect:'none',color:'#DB4C58',fontWeight:600}}>
              <Table.Cell>{ e.room }</Table.Cell>
              <Table.Cell>{ e.name }</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>{ e.ref }</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
});

export default class ProgrammingAdmin extends Component {
  dz = null;

  token = '';
  user_id = '';

  state = {
    programming: null,
    checking: null,
    activePreshow: {},
    lastChecking: {},
    mediaAttachmentInfo: null,
    info: ''
  }

  async componentDidMount() {
    this.token = await localforage.getItem('token');
    this.user_id = await localforage.getItem('user_id');

    this.listProgramming();
    this.listChecking();
    this.listOrdersWithActivePreshow();
  }

  async listProgramming() {
    try {
      const res = await window.cs_get('/programming');
      this.setState({ programming: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async listChecking() {
    const { userData } = this.props;
    if (userData.role !== IDs.Admin && userData.role !== IDs.Unid && this.props.userData.role !== IDs.Manager) return;

    try {
      const res = await window.cs_get('/programming/checking');
      this.setState({ checking: res.data.sort((x, y) => {
          if (x.room > y.room) return 1;
          else if (x.room < y.room) return -1;
          else return 0;
        })
      });

      const res2 = (await window.cs_get('/programming/checking/active')).data.sort((x, y) => {
        if (x.room > y.room) return 1;
        else if (x.room < y.room) return -1;
        else return 0;
      })

      let obj = {};
      for (const c of res2) {
        if (!obj[c.theater_id]) {
          obj[c.theater_id] = [];
        }

        obj[c.theater_id].push(c);
      }

      this.setState({ lastChecking: obj });
    } catch (err) {
      console.error(err);
    }
  }

  async listOrdersWithActivePreshow() {
    if (this.props.userData.role === IDs.Manager) return;

    try {
      const res = await window.cs_get('/orders/active-preshow');
      this.setState({ activePreshow: res.data });
    } catch (err) {
      console.error(err);
    }
  }

	/**
	 * 
	 * @param {*} status 
	 * @param {*} mediaAttachment 
	 */
	async checkMediaAttachment(status, mediaAttachment) {
    // Verifica se a mídia foi cadastrada
    if(!mediaAttachment.ref){
      alert('A mídia dessa sala não foi cadastrada. Por favor, cadastre a mídia antes de prosseguir.');
    }else{
      try {
        let mediaInfo = {
          status: status,
          week: mediaAttachment.week,
          order_id: mediaAttachment.order_id,
          theater_id: mediaAttachment.theater_id,
          media_attachment_id: mediaAttachment.media_attachment_id,
          room: mediaAttachment.room,
          ref: mediaAttachment.ref
        };
  
        await window.cs_post('/programming/checking', mediaInfo);
  
        this.listChecking();
      } catch (err) {
        alert('Ocorreu um erro ao salvar as informações. Por favor aguarde um momento e tente novamente.');
      }
    }
	}

  async saveMediaAttachmentInfo() {
    try {
      await window.cs_post(`/programming/checking/${this.state.mediaAttachmentInfo}/info`, { info: this.state.info });
      this.setState({ mediaAttachmentInfo: null, info: '' }, () => {
        this.listChecking();
      });
    } catch (err) {
      alert('Ocorreu um erro ao salvar as informações. Por favor aguarde um momento e tente novamente.');
    }
  }

  setMediaAttachmentInfo(mediaAttachment, info) {
    this.setState({ mediaAttachmentInfo: mediaAttachment, info: info || '' });
  }

  async uncheckMediaAttachment(checkingId) {
    try {
      await window.cs_delete('/programming/checking/' + checkingId);
      this.listChecking();
    } catch (err) {}
  }

  async onSortEnd({ oldIndex, newIndex}, list) {
    if (oldIndex === newIndex) return;

    const newList = arrayMove(list, oldIndex, newIndex);
    const checking = [ ...this.state.checking ];

    const getIdx = (e) => {
      for (let i = 0; i < checking.length; i++) {
        if (checking[i] === e) {
          return i;
        }
      }

      return -1;
    }

    let update = [];
    for (let i = 0; i < newList.length; i++) {
      let idx = getIdx(newList[i]);

      if (idx === -1) continue;
      checking[idx].idx = i;
      update.push({
        week: checking[idx].week,
        media_attachment_id: checking[idx].media_attachment_id,
        idx: i
      });
    }

    this.setState({ checking: checking });

    try {
      await window.cs_post('/programming/checking/order', update);
    } catch (err) {
      this.listChecking();
    }
  };

  getProgramming(networkId) {
    const orders = this.state.activePreshow.orders ? this.state.activePreshow.orders.map((e) => {
      return {
        ...e,
        theater: getTheaterById(e.theater_id)
      };
    }) : [];

    return this.state.programming.map((e) => {
      return {
        ...e,
        theater: getTheaterById(e.theater_id)
      };
    }).sort((x, y) => {
      if (x.theater.name < y.theater.name) return -1;
      else if (x.theater.name > y.theater.name) return 1;
      else return 0;
    }).filter((e) => {
      if (this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid)
        return true;

      if (orders.length === 0) return true;
      for (let order of orders) if (order.theater.id === e.theater.id) return true;
      return false;
    }).filter((e) => {
      if (!e.theater || e.theater.network.id !== networkId) return false;
      else return true;
    });
  }

  renderProgramming(networkId) {
    return this.getProgramming(networkId).map((e) => {
      if (this.props.userData.role === IDs.Manager) {
        const refs = this.props.userData.refs.map((e) => e.id);
        if (refs.indexOf(e.theater.id) === -1) return null;
      }

      const rooms = e.rooms;

      return (
        <div key={e.theater.id} className='TheaterProgramming'>
          <h2>{ e.theater.name }</h2>
          { rooms.map((r) => {
            return (
              <div key={r.room}>
                <h3>{ r.room }</h3>
                { r.movies.map((m) => {
                  return (
                    <div key={m.movie.name} style={{minHeight:140}}>
                      <figure className="poster" style={{backgroundImage:m.poster?`url(https://image.tmdb.org/t/p/w300${m.poster})`:'url(/imgs/no-poster.jpg)'}} />

                      <h4>{ m.movie.classification ? `[${m.movie.classification}]` : null} { m.movie.name }</h4>
                      { m.days.map((d) => {
                        return (
                          <div key={d.day}>
                            <h5>{ moment(d.day).format('ddd') }<br/>{ moment(d.day).format('DD/MMM') }</h5>
                            { d.hours.map((h) => {
                              return <p key={h}>{ h }</p>
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )
                })}
              </div>
            );
          })}
        </div>
      );
    });
  }

  renderControl() {
    if (!this.state.activePreshow.orders) return null;
    let ret = [];

    const orders = this.state.activePreshow.orders.map((e) => {
      return {
        ...e,
        theater: getTheaterById(e.theater_id)
      };
    }).sort((x, y) => {
      if (x.theater.name < y.theater.name) return -1;
      else if (x.theater.name > y.theater.name) return 1;
      else return 0;
    }).filter((order, idx, arr) => {
      return arr.findIndex(e => e.order_id === order.order_id && e.theater_id === order.theater_id) === idx;
    });

    let currentTheater = -1;

    function __render(t) {
      return orders.map((e, i) => {
        if (e.theater_id !== currentTheater) return null;
        return <OrderQuotaEditor key={`${e.order_id}${i}`} order={e.order_id} programmingSelect programmingOrder={e} userData={t.props.userData} />
      });
    }

    for (let e of orders) {
      if (currentTheater === e.theater_id) continue;
      currentTheater = e.theater_id;

      const theater = getTheaterById(e.theater_id);

      ret.push(
        <TitledBlock key={`${e.order_id}_${e.theater_id}`} style={{marginBottom:0,paddingBottom:0}} title={theater.name||'?'}>
          { __render(this) }
        </TitledBlock>
      );
    }

    return ret;
  }

  renderChecking() {
    let ret = [];

    let theaters = [];
    for (let checking of this.state.checking) {
      let found = false;

      for (let t of theaters) {
        if (t.id === checking.theater_id) {
          found = true;
          break;
        }
      }

      if (!found)
        theaters.push(getTheaterById(checking.theater_id));
    }

    theaters = theaters.sort((x, y) => {
      if (x.name < y.name) return -1;
      else if (x.name > y.name) return 1;
      else return 0;
    });

    for (let theater of theaters) {
      if (this.props.userData.role === IDs.Manager) {
        const refs = this.props.userData.refs.map((e) => e.id);
        if (refs.indexOf(theater.id) === -1) continue;
      }

      const checking = this.state.checking.filter((e) => e.theater_id === theater.id).sort((x, y) => {
        if (x.idx > y.idx) return 1;
        else if (x.idx < y.idx) return -1;
        else return 0;
      });

      ret.push(
        <div key={theater.id}>
          <h1 style={{backgroundColor:'#EEE',padding:12,fontSize:14,color:'#555',margin:-1,border:'1px solid rgba(34,36,38,.15)'}}>{ theater.name }</h1>
          <ContentList helperClass='sorting-row' lockAxis='y' useWindowAsScrollContainer={true} useDragHandle={true} checking={checking} _this={this} onSortEnd={(e) => this.onSortEnd(e, checking)} />
        </div>
      );
    }

    return ret;
  }

  render() {
    const djsConfig = {
      timeout: 0,
      maxFilesize: 100,
      addRemoveLinks : true,
      autoProcessQueue: true,
      dictDefaultMessage: '<h1>Clique ou arraste os arquivos aqui para fazer upload da programação.</h1>',
      dictResponseError: 'Erro ao tentar fazer upload',
      dictFallbackText: 'Envie os arquivos abaixo.',
      dictFileTooBig: 'O arquivo é muito grande. Tamanho máximo: 100 MB.',
      dictInvalidFileType: 'Você nao pode enviar arquivos desse tipo.',
      dictCancelUpload: 'Cancelar upload',
      dictCancelUploadConfirmation: 'Você tem certeza que deseja cancelar esse upload?',
      dictRemoveFile: 'Remover da lista',
      dictRemoveFileConfirmation: null,
      dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.'
    };

    const dropzoneConfig = {
      postUrl: process.env.NODE_ENV === 'production' ? '/programming' : 'http://localhost:3080/programming'
    };

    const eventHandlers = {
      init: (dz) => {
        this.dz = dz;
      },
      sending: (file, xhr, formData) => {
        xhr.setRequestHeader('x-userid', this.user_id);
        xhr.setRequestHeader('x-token', this.token);
      },
      complete: (file) => {
        if (file.status === 'success') {
          setTimeout(() => {
            this.dz.removeFile(file);
            if (this.dz.files.length === 0) this.listProgramming();
          }, 1500);
        }
      }
    };

    let panes = [
      {
        menuItem: <Menu.Item key={1}>Programação{ this.state.activePreshow.period ? <span style={{fontSize:11,marginLeft:4,color:'#999'}}>({ moment(this.state.activePreshow.period.start).format('DD/MMM') } à { moment(this.state.activePreshow.period.end).format('DD/MMM') })</span> : null }</Menu.Item>,
        render: () => (
          <Tab.Pane loading={!this.state.programming}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  { this.props.userData.role === IDs.Admin && <DropzoneComponent ref='dz' config={dropzoneConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} /> }

                  { this.props.userData.role !== IDs.Admin && this.state.programming && this.state.programming.length === 0 &&
                    <Message warning>
                      <Message.Header>Programação não disponível!</Message.Header>
                      <p>A programação ainda não foi disponibilizada por nossos parceiros. Por favor aguarde alguns minutos e tente novamente.</p>
                    </Message>
                  }

                  {/* this.state.programming && this.state.programming.length > 0 && this.renderProgramming(1) */}

                  { this.state.programming && this.state.programming.length > 0 &&
                    <Tab style={{marginTop:16}} menu={{secondary:true,pointing:true}} panes={[
                      {
                        menuItem: <Menu.Item key={1} disabled={this.getProgramming(1).length===0}>CINEFLIX</Menu.Item>,
                        render: () => (
                          <Tab.Pane style={{border:0,padding:0}}>
                            { this.renderProgramming(1) }
                          </Tab.Pane>
                        )
                      },
                      {
                        menuItem: <Menu.Item key={2} disabled={this.getProgramming(721).length===0}>LUMIÈRE</Menu.Item>,
                        render: () => (
                          <Tab.Pane style={{border:0,padding:0}}>
                            { this.renderProgramming(721) }
                          </Tab.Pane>
                        )
                      }
                    ]} />
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid || this.props.userData.role === IDs.Agency || this.props.userData.role === IDs.Advertiser ?
      {
        menuItem: <Menu.Item key={2}>Controle</Menu.Item>,
        render: () => (
          <Tab.Pane>
            <Grid style={{paddingBottom:22}}>
              { this.renderControl() }
            </Grid>
          </Tab.Pane>
        )
      } : null,
      this.props.userData.role === IDs.Admin || this.props.userData.role === IDs.Unid || this.props.userData.role === IDs.Manager ?
      {
        menuItem: <Menu.Item key={3}>Checking</Menu.Item>,
        render: () => (
          <Tab.Pane>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  { (!this.state.checking || this.state.checking.length === 0) &&
                    <Message warning>
                      <Message.Header>Checking não disponível!</Message.Header>
                      <p>O checking ainda está sendo processado. Por favor aguarde alguns minutos e tente novamente.</p>
                    </Message>
                  }

                  { this.state.checking && this.state.checking.length > 0 && this.renderChecking() }

                  <Modal open={!!this.state.mediaAttachmentInfo} size='tiny'>
                    <Modal.Content>
                      <Form>
                        <Form.Input autoComplete='off' width='16' label='Observações sobre o checking' value={this.state.info} onChange={(e, { value }) => this.setState({ info: value })} />
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button size='small' negative content='Cancelar' onClick={()=>this.setState({ mediaAttachmentInfo: null })} />
                      <Button size='small' primary labelPosition='right' icon='check' content='Salvar' onClick={this.saveMediaAttachmentInfo.bind(this)}/>
                    </Modal.Actions>
                  </Modal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      } : null
    ];

    return (
      <Grid padded>
        <ContentHeader userData={this.props.userData} path={['Programação']}/>

        <Grid.Row className='content-margin'>
          <Grid.Column>
            <Tab panes={panes} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
