/**
 * Formata um número de documento (CPF ou CNPJ) removendo caracteres não numéricos e aplicando a formatação adequada.
 *
 * @param {string} document Número de documento (CPF ou CNPJ) a ser formatado.
 * @returns {string} Documento formatado (ex: "000.000.000-00" para CPF ou "00.000.000/0000-00" para CNPJ), ou o próprio valor caso não seja um CPF ou CNPJ válido.
 */
export const formatDocument = (document) => {
    if (!document) return document; // Se for null ou undefined, retorna o próprio valor

    // Remove tudo que não for número
    const cleanDoc = document.replace(/\D/g, '');

    // Formatação para CPF
    if (cleanDoc.length === 11) {
        return cleanDoc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    // Formatação para CNPJ
    if (cleanDoc.length === 14) {
        return cleanDoc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    return document; // Caso não seja nem CPF nem CNPJ
};

/**
 * Formata uma string de data no formato DD/MM/AAAA às HH:MM:SS.
 *
 * @param {*} dateString Data em formato de string para ser formatada.
 * @returns {string} Data formatada no formato "DD/MM/AAAA às HH:MM:SS" ou uma string vazia se a data for inválida.
 */
export const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`;
};

/**
 * Resume um texto para um tamanho específico, cortando-o na última palavra antes do limite de comprimento e adicionando reticências caso o texto seja maior que o comprimento especificado.
 *
 * @param {string} text Texto a ser resumido.
 * @param {number} length Limite de comprimento do texto.
 * @returns {string} Texto resumido até o limite de comprimento, com reticências se o texto original for maior que o limite.
 */
export const resumeText = (text, length) => {
    if (text.length <= length) return text;

    const truncated = text.substring(0, length);
    const lastSpaceIndex = truncated.lastIndexOf(" ");
    
    // Se encontrar um espaço, corta até o espaço, senão mantém o truncado
    const result = lastSpaceIndex !== -1 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return result + '...';
};
