import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Breadcrumb, Header, Button } from 'semantic-ui-react';

import IDs from 'unid-core/lib/utils/IDs';

import { GetCentralName } from '../routes';

import './ContentHeader.css';

export default (props) => {
	let showAdButton = false;

	const role = props && props.userData ? props.userData.role : null;
	if (role === IDs.Admin || role === IDs.Unid/* || role === IDs.Agency || role === IDs.Advertiser || role === IDs.Manager*/) {
		showAdButton = true;
	}

	return (
		<Grid.Row className='ContentHeader'>
			<Grid.Column mobile={10} computer={12} largeScreen={13} widescreen={14} >
				<Breadcrumb>
					<Breadcrumb.Section>{GetCentralName()}</Breadcrumb.Section>
					{ props.path && props.path.map((e, i) => {
						return  <span key={e}>
								<Breadcrumb.Divider icon='right angle' />
								<Breadcrumb.Section active={i===props.path.length-1}>{e}</Breadcrumb.Section>
								</span>
					})}
				</Breadcrumb>

				<Header as='h1' color='blue'>{ props.path ? props.path.pop() : GetCentralName()}</Header>
			</Grid.Column>

			{ showAdButton &&
				<Grid.Column mobile={6} computer={4} largeScreen={3} widescreen={2} className='advertisement'>
					<Link to="/compra-online">
						<Button fluid color='olive'>Criar Anúncio</Button>
					</Link>
				</Grid.Column>
			}
		</Grid.Row>
	);
}