import React, { Component } from 'react';
import { Grid, Button, Form, Input } from 'semantic-ui-react';
import ContentHeader from './../components/ContentHeader';

export default class Youtube extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: 'https://www.youtube.com/watch?v=cZeVbIA1Tk0',
			isDownloading: false,
		};
	}

	// Função para lidar com a mudança da URL
	handleInputChange = (e) => {
		this.setState({ url: e.target.value });
	};

	// Função para fazer o download
	handleDownload = async () => {
		const { url } = this.state;

		if (url) {
			this.setState({ isDownloading: true });
		
			try {
				// 
				await window.cs_post(`/youtube/download`, { url: url });

				alert('Download concluído com sucesso!');
			} catch (error) {
				console.error('Erro ao baixar o vídeo:', error);

				alert('Ocorreu um erro durante o download.');
			} finally {
				this.setState({ isDownloading: false });
			}
		} else {
			alert('Por favor, insira a URL do vídeo do YouTube.');
		}
	};

	render() {
		const { url, isDownloading } = this.state;

		return (
			<Grid padded>
				<ContentHeader userData={this.props.userData} path={['Youtube Downloader Test']} />
				
				<Grid.Row className='content-margin'>
					<Grid.Column>
						<Form>
							<Form.Field>
								<label>URL do vídeo do YouTube</label>
								<Input
									placeholder='Insira a URL do vídeo'
									value={url}
									onChange={this.handleInputChange}
									disabled={isDownloading}
								/>
							</Form.Field>
							<Button 
								primary 
								onClick={this.handleDownload}
								disabled={!url || isDownloading}
							>
								{isDownloading ? 'Baixando...' : 'Baixar Vídeo'}
							</Button>
						</Form>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}
