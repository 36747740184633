import React, { Component } from 'react';
import { Grid, Tab, Message } from 'semantic-ui-react';
import ContentHeader from './../components/ContentHeader';

import Clients from './ContaAzul/Clients';
import Logs from './ContaAzul/Logs';
import Config from './ContaAzul/Config';

export default class ContaAzul extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeIndex: this.getActiveIndexFromHash(),
			message: null,
			error: null
		};
	}

	componentDidMount() {
		window.addEventListener('hashchange', this.handleHashChange);
		this.checkForCodeInUrl();
	}
	
	componentWillUnmount() {
		window.removeEventListener('hashchange', this.handleHashChange);
	}
	
	getActiveIndexFromHash = () => {
		const hash = window.location.hash;

		switch (hash) {
			case '#config':
				return 2;

			case '#logs':
				return 1;

			case '#clients':
			default:
				return 0;
		}
	};
	
	handleHashChange = () => {
		this.setState({ activeIndex: this.getActiveIndexFromHash() });
	};

	handleTabChange = (e, { activeIndex }) => {
		this.setState({ activeIndex });

		switch (activeIndex) {
			case 2:
				window.location.hash = '#config';
				break;

			case 1:
				window.location.hash = '#logs';
				break;

			case 0:
			default:
				window.location.hash = '#clients';
				break;
		}
	};

	checkForCodeInUrl = async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');

		if (code) {
			this.setState({ message: 'Login no Conta Azul identificado! Por favor, aguarde a verificação de token e o redirecionamento para as configurações.', error: null });
			try {
				await window.cs_post('/contaazul/token', { code });
				window.location.href = window.location.origin + window.location.pathname + '#config';
			} catch (error) {
				console.error('Erro ao obter o token:', error);
				this.setState({ message: null, error: `Erro ao obter o token: ${error.message}` });
			}
		}
	};

	render() {
		const { message, error } = this.state;
		const panes = [
			{ menuItem: 'Clientes', render: () => <Tab.Pane><Clients /></Tab.Pane> },
			{ menuItem: 'Logs', render: () => <Tab.Pane><Logs /></Tab.Pane> },
			{ menuItem: 'Configurações', render: () => <Tab.Pane><Config /></Tab.Pane> }, 
		];

		return (
			<Grid padded>
				<ContentHeader userData={this.props.userData} path={['Conta Azul']} />

				<Grid.Row className='content-margin'>
					<Grid.Column>
						{message && <Message info>{message}</Message>}
						{error && <Message negative>{error}</Message>}
						
						<Tab panes={panes} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}