import React, { Component } from 'react';
import { toast } from 'react-toastify';

import { removeAccents } from 'unid-core/lib/utils/string';

import { Grid, Tab, Menu, Header, Button, Icon, Modal, Input, List, Label, Popup } from 'semantic-ui-react';
import ContentHeader from '../../components/ContentHeader';
import EntityEditor from './EntityEditor';
import UserEditor from './UserEditor';

import NetworkSettings from './NetworkSettings';

import { formatNumber } from 'unid-core/lib/utils/formatter';
import IDs from 'unid-core/lib/utils/IDs';

import { getRoomsByTheater } from 'unid-core/lib/Theaters';

import './Entries.css';
export default class Entries extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 0,
      removeEntity: null,
      searchText: '',
      agency: {
        loading: false,
        list: [],
        search: null
      },
      advertiser: {
        loading: false,
        list: [],
        search: null
      },
      network: {
        loading: false,
        list: [],
        search: null
      },
      theater: {
        loading: false,
        list: [],
        search: null
      },
      user: {
        loading: false,
        list: [],
        search: null
      },
      taxes: {
        loading: false,
        municipalTax: 0,
        federalTax: 0
      },
      disabledButtons: {},
      networkConfigSelected: null
    };
  }

  onMunicipalTaxChanged(e, { value }) {
    const municipalTax = (parseInt(value.replace(/,/g,''), 10)||0) / 100;
    this.setState({ taxes: { ...this.state.taxes, municipalTax } });
  }

  onFederalTaxChanged(e, { value }) {
    const federalTax = (parseInt(value.replace(/,/g,''), 10)||0) / 100;
    this.setState({ taxes: { ...this.state.taxes, federalTax } });
  }

  setEntityState(entity, obj) {
    if (obj['list']) {
      for (let e of obj['list']) {
        if (e['type'] === undefined) continue;
        e['contacts'] = JSON.parse(e['contacts']);
        e['additional_data'] = JSON.parse(e['additional_data']);
      }
    }
    
    this.setState({ [entity]: { ...this.state[entity], ...obj } });
  }

  entityEditorClosed() {
    this.setEntityState('agency', { search: null });
    this.setEntityState('advertiser', { search: null });
    this.setEntityState('network', { search: null });
    this.setEntityState('theater', { search: null });
    this.setEntityState('user', { search: null });

    this.updateAgencyList();
    this.updateAdvertiserList();
    this.updateNetworkList();
    this.updateTheaterList();
    this.updateUserList();
    this.updateVars();
  }

  async updateAgencyList() {
    this.setEntityState('agency', { loading: true });
    const res = await window.cs_get('/entities', { type: 2 });
    if (res.status === 200) this.setEntityState('agency', { loading: false, list: res.data });
  }

  async updateAdvertiserList() {
    this.setEntityState('advertiser', { loading: true });
    const res = await window.cs_get('/entities', { type: 3 });
    if (res.status === 200) this.setEntityState('advertiser', { loading: false, list: res.data });
  }

  async updateNetworkList() {
    this.setEntityState('network', { loading: true });
    const res = await window.cs_get('/entities', { type: 0 });
    if (res.status === 200) this.setEntityState('network', { loading: false, list: res.data });
  }

  async updateTheaterList() {
    this.setEntityState('theater', { loading: true });
    const res = await window.cs_get('/entities', { type: 1 });
    if (res.status === 200) this.setEntityState('theater', { loading: false, list: res.data });
  }

  async updateUserList() {
    this.setEntityState('user', { loading: true });
    const res = await window.cs_get('/users');
    if (res.status === 200) this.setEntityState('user', { loading: false, list: res.data });
  }

  async updateVars() {
    this.setState({ taxes: { ...this.state.taxes, loading: true }});
   
    try {
      const res = await window.cs_get('/vars');

      let municipalTax = '';
      let federalTax = '';
      
      for (const row of res.data) {
        if (row.name === 'municipal_nfe_tax') municipalTax = row.value;
        if (row.name === 'federal_nfe_tax') federalTax = row.value;
      }

      this.setState({ taxes: { loading: false, municipalTax, federalTax }});
    } catch {
      this.setState({ taxes: { ...this.state.taxes, loading: false }});
    }
  }

  async saveVars() {
    this.setState({ taxes: { ...this.state.taxes, loading: true }});

    try {
      await window.cs_post('/vars', { municipalTax:this.state.taxes.municipalTax, federalTax: this.state.taxes.federalTax });
     
      const content = (
        <div>
          <Header as='h5' style={{margin:'6px 0 6px 0',color:'#FFF'}}><Icon name='info circle' />Sucesso!</Header>
          <p>As taxas dos tributos foram salvas com sucesso.</p>
        </div>
      );

      toast.success(content, { autoClose: 5000, closeButton: true });
    } catch {
      const content = (
        <div>
          <Header as='h5' style={{margin:'6px 0 6px 0',color:'#FFF'}}><Icon name='warning sign' />Problema ao salvar!</Header>
          <p>Ocorreu um erro inesperado e não foi possível salvar as taxas dos tributos.</p>
        </div>
      );

      toast.error(content, { autoClose: 5000, closeButton: true });
    } finally {
      this.setState({ taxes: { ...this.state.taxes, loading: false }});
    }
  }

  componentDidMount() {
    this.updateAgencyList();
    this.updateAdvertiserList();
    this.updateNetworkList();
    this.updateTheaterList();
    this.updateUserList();
    this.updateVars();
  }

  firstContact(type, contacts) {
    let ret = null;

    for (let contact of contacts) {
      if (contact.type === type) {
        if (contact.tags.indexOf('Contato') !== -1) return contact.value;
        else ret = contact.value;
      }
    }

    return ret;
  }

  contactInfo(e) {
    const style = { fontSize: 11, marginTop: 2 };

    const agency = e.agency;
    const firstEmail = this.firstContact('email', e.contacts);
    const firstPhone = this.firstContact('phone', e.contacts);
    const place = e.city && e.uf ? `${e.city} - ${e.uf}` : '';

    let ret = [
      agency ? <List.Description style={style} key='agency'><Icon disabled name='building' />{agency}</List.Description> : null,
      <List.Description style={style} key='email'><Icon disabled name='mail' />{firstEmail||'Nenhum email'}</List.Description>,
      <List.Description style={style} key='phone'><Icon disabled name='phone' />{firstPhone||'Nenhum telefone'}</List.Description>,
      place ? <List.Description style={style} key='place'><Icon disabled name='marker' />{place}</List.Description> : null
    ];
  
    return ret;
  }

  removeEntityConfirm(entity) {
    this.setState({ removeEntity: entity });
  }

  async asUser(user) {
    await window.cs_post('/user/as', { userId: user.id });
    window.location = '/';
  }

  async removeEntity() {
    const id = this.state.removeEntity.id;
    this.setState({ removeEntity: null });

    // User ?
    if (!isNaN(this.state.removeEntity.role)) {
      await window.cs_delete(`/users/${id}`);
      this.updateAgencyList();
      this.updateAdvertiserList();
      this.updateNetworkList();
      this.updateTheaterList();
      this.updateUserList();
    } else {
      await window.cs_delete(`/entities/${id}`);
      this.updateAgencyList();
      this.updateAdvertiserList();
      this.updateNetworkList();
      this.updateTheaterList();
      this.updateUserList();
    }
  }

  doSearchKey(key, value, e) {
    if (!value || !e[key]) return false;
    return removeAccents(e[key]).toLowerCase().indexOf(removeAccents(value).toLowerCase()) !== -1;
  }

  doSearch(type, value) {
    this.setState({ searchText: value, page: 0 });
    
    if (!value) {
      this.setEntityState(type, { search: null });
      return;
    }

    let fine_results = [];
    let sparse_results = [];

    for (let entity of this.state[type].list) {
      if (this.doSearchKey('name', value, entity))
        fine_results.push(entity);
      
      if (type === 'user') {
        if (removeAccents(this.roleName(entity.role)).toLowerCase().indexOf(removeAccents(value).toLowerCase()) !== -1)
          sparse_results.push(entity);

        continue;
      }

      if (type === 'advertiser') {
        if (this.doSearchKey('agency', value, entity))
          sparse_results.push(entity);
      }

      if (this.doSearchKey('identity', value, entity))
        fine_results.push(entity);
      if (this.doSearchKey('address', value, entity))
        sparse_results.push(entity);
      if (this.doSearchKey('district', value, entity))
        sparse_results.push(entity);
      if (this.doSearchKey('city', value, entity))
        sparse_results.push(entity);
      if (this.doSearchKey('state', value, entity))
        sparse_results.push(entity);
      if (this.doSearchKey('uf', value, entity))
        sparse_results.push(entity);
    }

    let allResults = fine_results.concat(sparse_results);

    function isPresentInArray(e, beforePos) {
      for (let i = 0; i < beforePos; i++) {
        if (allResults[i].id === e.id) return true;
      }
      return false;
    }

    for (let i = allResults.length -1; i >= 0; i--) {
      if (isPresentInArray(allResults[i], i)) allResults.splice(i, 1);
    }

    this.setEntityState(type, { search: allResults });
  }

  entitiesPerPage = 10;

  pagination(type) {
    let entities = this.state[type].search || this.state[type].list;
    const pageCount = Math.ceil(entities.length / this.entitiesPerPage);

    if (pageCount <= 1) return null;

    let startIdx = 0;
    let endIdx = pageCount;

    if (pageCount > 7) {
      startIdx = this.state.page - 3;
      endIdx = this.state.page + 4;

      const left_factor = this.state.page - 3;
      const right_factor = (this.state.page + 4) - pageCount;

      if (left_factor < 0) {
        startIdx = 0;
        endIdx += Math.abs(left_factor);
      }

      if (right_factor > 0) {
        startIdx -= right_factor;
        endIdx -= right_factor;
      }
    }

    let pages = [];
    if (startIdx !== 0)
      pages.push(<Menu.Item key={0} onClick={()=>this.setState({ page: 0 })}>&lt;&lt; Início</Menu.Item>);

    for (let i = startIdx; i < endIdx; i++) {
      pages.push(<Menu.Item key={i} active={i === this.state.page} onClick={()=>this.setState({ page: i })}>{i+1}</Menu.Item>);
    }

    if (endIdx < pageCount)
      pages.push(<Menu.Item key={pageCount-1} onClick={()=>this.setState({ page: pageCount-1 })}>Fim &gt;&gt;</Menu.Item>);

    return (
      <Menu pagination size='mini' >
        { pages }
      </Menu>
    );
  }

  roleName(role) {
    switch(role) {
      case IDs.Admin: return 'Administrador';
      case IDs.Unid: return 'Equipe UNID';
      case IDs.Exhibitor: return 'Exibidor';
      case IDs.Manager: return 'Gerente';
      case IDs.Marketing: return 'Marketing';
      case IDs.Agency: return 'Agência';
      case IDs.Advertiser: return 'Anunciante';
      default: return '?';
    }
  }

  roomInfo(room) {
    return (
      <div key={room.ref}>
        <span className='icone-midia-em-tela' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> <span style={{minWidth:80,display:'inline-block'}}>{room.ref||'SALA SEM ID'}</span>
        <span className='icone-poltrona' style={{position:'relative',top:2,fontSize:16,margin:0}}></span> <span style={{minWidth:40,display:'inline-block'}}>{room.seats}</span>
        <span style={{minWidth:80,display:'inline-block'}}>{room.tags.split(',').join(' | ')}</span>
      </div>
    );
  }

  async contaAzulPost(entity) {

    if (window.confirm('Tem certeza que deseja criar o cadastro desse cliente na Conta Azul?')) {
      this.setState(prevState => ({ disabledButtons: { ...prevState.disabledButtons, [entity.id]: true } }));

      try {
        //
        await window.cs_post('/contaazul/postCustomer', { e: entity.id });

        toast.success('O cliente '+entity.name+' foi cadastrado no Conta Azul com sucesso.');

        this.updateAdvertiserList();
      } catch (error) {       
        //     
        toast.error(`Erro ao cadastrar cliente na Conta Azul: ${error.response?.data?.error.message || error}`);
      } finally {
        this.setState(prevState => ({ disabledButtons: { ...prevState.disabledButtons, [entity.id]: false } }));
      }
    }
  };

  editNetworkSettings = (entity) => {
    this.setState({ networkConfigSelected: entity });
  };

  closeNetworkSettings = () => {
    this.setState({ networkConfigSelected: null });
  };

  entitiesList(type) {
    let entities = this.state[type].search || this.state[type].list;
    let list = [];

    for (let i = this.state.page * this.entitiesPerPage; i < entities.length; i++) {
      const entity = entities[i];
      const rooms = getRoomsByTheater(entity.id);

      if (list.length >= this.entitiesPerPage) break;

      list.push(
        <List.Item key={entity.id}>
          <List.Content floated='right'>

              {type === 'advertiser' && entity.contaazul_match === 0 && (
                <Popup size='small' inverted hideOnScroll position='top right' mouseEnterDelay={0} mouseLeaveDelay={0} content='Cadastrar no Conta Azul' trigger={
                    <Button color='purple' size='small' icon='arrow up' disabled={this.state.disabledButtons[entity.id]} loading={this.state.disabledButtons[entity.id]} onClick={() => this.contaAzulPost(entity)} />
                } />
              )}

              {type === 'network' && (
                <Popup size='small' inverted hideOnScroll position='top right' mouseEnterDelay={0} mouseLeaveDelay={0} content='Configurações' trigger={
                  <Button color='secondary' size='small' icon='cog' onClick={() => this.editNetworkSettings(entity) } />
                } />
              )}

              <Button primary size='small' icon='pencil' onClick={() => this.refs.entityEditor.editEntity(type, entity) } />
              <Button disabled={this.props.userData.role !== IDs.Admin} negative size='small' icon='trash' onClick={() => this.removeEntityConfirm(entity) }/>

          </List.Content>

          { rooms.length > 0 &&
            <List.Content floated='right'>
              { rooms.map(this.roomInfo) }
            </List.Content>
          }
          
          <List.Content>
            <List.Header>{entity.name||'?'}{entity.identity?' - '+entity.identity:null}</List.Header>
            {this.contactInfo(entity)}
          </List.Content>
        </List.Item>
      );
    }

    return list;
  }

  usersList() {
    let users = this.state['user'].search || this.state['user'].list;
    let list = [];

    for (let i = this.state.page * this.entitiesPerPage; i < users.length; i++) {
      const user = users[i];

      if (list.length >= this.entitiesPerPage) break;

      list.push(
        <List.Item key={user.id}>
          <List.Content floated='right'>
            <Button disabled={this.props.userData.role !== IDs.Admin} color='olive' size='small' icon='sign in' onClick={() => this.asUser(user) } />
            <Button disabled={this.props.userData.role !== IDs.Admin} primary size='small' icon='pencil' onClick={() => this.refs.userEditor.editUser(user) } />
            <Button disabled={this.props.userData.role !== IDs.Admin} negative size='small' icon='trash' onClick={() => this.removeEntityConfirm(user) }/>
          </List.Content>

          <List.Content>
            <List.Header>{user.name||'?'}</List.Header>
            <List.Description style={{ fontSize: 11, marginTop: 2 }} key='users'><Icon disabled name='users' />{this.roleName(user.role)}</List.Description>
            <List.Description style={{ fontSize: 11, marginTop: 2 }} key='email'><Icon disabled name='mail' />{user.email}</List.Description>
          </List.Content>
        </List.Item>
      );
    }

    return list;
  }

  render() {
    const { networkConfigSelected } = this.state;

    const panes = [
      {
        menuItem: <Menu.Item key={1}><span className="icone-central"></span>Agências<Label>{(this.state.agency.search||this.state.agency.list).length}</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.agency.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch('agency', value)} />
                  <Button primary floated='right' size='small' onClick={() => this.refs.entityEditor.editEntity('agency')}>Adicionar Agência</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List divided relaxed='very'>
                    { this.entitiesList('agency') }
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  { this.pagination('agency') }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={2}><span className="icone-megafone"></span>Anunciantes<Label>{(this.state.advertiser.search||this.state.advertiser.list).length}</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.advertiser.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch('advertiser', value)} />
                  <Button primary floated='right' size='small' onClick={() => this.refs.entityEditor.editEntity('advertiser')}>Adicionar Anunciante</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List divided relaxed='very'>
                    { this.entitiesList('advertiser') }
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  { this.pagination('advertiser') }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={3}><span className="icone-estrela-contorno"></span>Redes<Label>{(this.state.network.search||this.state.network.list).length}</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.network.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch('network', value)} />
                  <Button primary floated='right' size='small' onClick={() => this.refs.entityEditor.editEntity('network')}>Adicionar Rede</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List divided relaxed='very'>
                    { this.entitiesList('network') }
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  { this.pagination('network') }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={4}><span className="icone-camera-video"></span>Cinemas<Label>{(this.state.theater.search||this.state.theater.list).length}</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.theater.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch('theater', value)} />
                  <Button primary floated='right' size='small' onClick={() => this.refs.entityEditor.editEntity('theater')}>Adicionar Cinema</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List divided relaxed='very'>
                    { this.entitiesList('theater') }
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  { this.pagination('theater') }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={5}><span className="icone-usuario"></span>Usuários<Label>{(this.state.user.search||this.state.user.list).length}</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.user.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Input style={{width:'40%'}} icon='search' placeholder='Pesquisar...' value={this.state.searchText} onChange={(e, { value })=>this.doSearch('user', value)} />
                  <Button disabled={this.props.userData.role !== IDs.Admin} primary floated='right' size='small' onClick={() => this.refs.userEditor.editUser()}>Adicionar Usuário</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <List divided relaxed='very'>
                    { this.usersList() }
                  </List>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{textAlign:'center'}}>
                  { this.pagination('user') }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={5}><span className="icone-porcentagem"></span>Impostos<Label>2</Label></Menu.Item>,
        render: () => (
          <Tab.Pane loading={this.state.user.loading}>
            <div style={{ display: 'flex', flexDirection:'column', width: '100%', maxWidth: 350, margin: '0 auto' }}>
              <Input size='large' disabled={this.state.taxes.loading} key={3} style={{marginTop:8}} labelPosition='right' type='text' value={formatNumber(this.state.taxes.federalTax, 2)} onChange={this.onFederalTaxChanged.bind(this)} >
                <Label basic style={{ width: 200 }}>Tributo federal</Label>
                <input style={{textAlign:'center',width:100} }/>
                <Label>%</Label>
              </Input>

              <Input size='large' disabled={this.state.taxes.loading} key={3} style={{marginTop:8}} labelPosition='right' type='text' value={formatNumber(this.state.taxes.municipalTax, 2)} onChange={this.onMunicipalTaxChanged.bind(this)} >
                <Label basic style={{ width: 200 }}>Tributo municipal</Label>
                <input style={{textAlign:'center',width:100} }/>
                <Label>%</Label>
              </Input>

              <Button primary size="large" disabled={this.state.taxes.loading} loading={this.state.taxes.loading} style={{ marginTop: 24, marginBottom: 8 }} onClick={() => this.saveVars()}>Salvar</Button>
            </div>
          </Tab.Pane>
        )
      }
    ];
   
    return (
      <Grid padded className='Entries'>
        <ContentHeader userData={this.props.userData} path={['Cadastros']}/>

        <Grid.Row className='content-margin'>
          <Grid.Column>
            <Tab panes={panes} onTabChange={() => {
              this.setState({ page: 0, searchText: '' });
              this.setEntityState('agency', { search: null });
              this.setEntityState('advertiser', { search: null });
              this.setEntityState('network', { search: null });
              this.setEntityState('theater', { search: null });
              this.setEntityState('user', { search: null });
            }} />
          </Grid.Column>
        </Grid.Row>

        <Modal closeOnDimmerClick={false} size={'tiny'} open={!!this.state.removeEntity}>
          <Modal.Header>
            Removendo cadastro
          </Modal.Header>
          <Modal.Content>
            <p>Você tem certeza que deseja remover <b>{this.state.removeEntity?this.state.removeEntity.name:null}</b>?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content='Não' onClick={()=>this.setState({ removeEntity: null})} />
            <Button positive icon='checkmark' labelPosition='right' content='Sim' onClick={this.removeEntity.bind(this)}/>
          </Modal.Actions>
        </Modal>

        <EntityEditor ref='entityEditor' agencies={this.state.agency.list} networks={this.state.network.list} onCloseModal={this.entityEditorClosed.bind(this)} />
        <UserEditor ref='userEditor' agencies={this.state.agency.list} advertisers={this.state.advertiser.list} networks={this.state.network.list} theaters={this.state.theater.list} onCloseModal={this.entityEditorClosed.bind(this)} />

        <NetworkSettings open={!!networkConfigSelected} onClose={this.closeNetworkSettings} entity={networkConfigSelected} />
      </Grid>
    );
  }
}