import React, { useEffect, useState } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import moment from 'moment';

const Config = () => {
    const [status, setStatus] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    useEffect(() => {
        checkAPIStatus();
    }, []);

    const checkAPIStatus = async () => {
        try {
            const response = await window.cs_get('/contaazul/status');
            console.log('Resposta do check:', response);
            setStatus(response.data);
        } catch (error) {
            console.error('Erro ao obter o check:', error);
            setStatus('Erro ao obter o status.');
        }
    };

    const handleLogin = () => {
        const clientId    = (process.env.NODE_ENV === 'production' ? '2YuXk4PPXXxwWr5ATBWTMd7t2LCFdRyk' : 'NHHwKZXF3njQCn2ECDDRgOw4fDKpJBl2');
        const redirectUri = window.location.origin + '/contaazul';
        const scope       = 'sales';
        const state       = 'unidcom';
        const authUrl     = `https://api.contaazul.com/auth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_type=code`;

        window.location.href = authUrl;
    };

    const handleLogout = async () => {
        setIsDisconnecting(true);
        try {
            await window.cs_get('/contaazul/disconnect');
            checkAPIStatus();
        } catch (error) {
            console.error('Erro ao desconectar:', error);
        }
        setIsDisconnecting(false);
    };

    const refreshToken = async () => {
        setIsUpdating(true);
        try {
            const response = await window.cs_get('/contaazul/refreshToken');
            console.log('Resposta do refresh:', response);
            checkAPIStatus();
        } catch (error) {
            console.error('Erro ao atualizar o token:', error);
        }
        setIsUpdating(false);
    };

    const isTokenExpired = status && status[0] && moment(status[0].contaazul_expires_at).isBefore(moment());
    const isConnected = status && Array.isArray(status) && status.length > 0;

    return (
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <Header as="h2">Configurações</Header>

                    {status && Array.isArray(status) ? (
                        <div>
                            <Header as="h3">Status de conexão com a API Conta Azul</Header>

                            {isTokenExpired ? (
                                <div>
                                    <p>Conexão perdida em {moment(status[0].contaazul_expires_at).format('DD/MM/YYYY [às] HH:mm:ss')}</p>
                                    <Button primary onClick={handleLogin}>Entrar com Conta Azul</Button>
                                </div>
                            ) : (
                                <div>
                                    <p>Última atualização: {moment(status[0].timestamp).format('DD/MM/YYYY [às] HH:mm:ss')}</p>

                                    <Button 
                                        secondary 
                                        onClick={refreshToken} 
                                        disabled={isUpdating}
                                    >
                                        {isUpdating ? 'Atualizando Token...' : 'Atualizar Token'}
                                    </Button>

                                    {isConnected && (
                                        <Button 
                                            color="red" 
                                            onClick={handleLogout} 
                                            disabled={isDisconnecting}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            {isDisconnecting ? 'Desconectando...' : 'Desconectar Conta'}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>Carregando...</p>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Config;