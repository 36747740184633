import React, { Component } from 'react';
import { Grid, Card, Image, Loader, Header } from 'semantic-ui-react';
//import { formatDate } from '../utils/functions';

import ContentHeader from '../components/ContentHeader';

import './Central.css';

export default class Central extends Component {
	state = {
		moviesNow: [],
		moviesSoon: [],
		loadingNow: true,
		loadingSoon: true
	};

	// Função para fazer a requisição GET aos filmes
	fetchMovies = async () => {
		try {
			// Filmes em exibição
			const responseNow = await window.cs_get('/tmdb/movies?status=now');

			this.setState({ moviesNow: responseNow.data, loadingNow: false });

			// Próximos lançamentos
			const responseSoon = await window.cs_get('/tmdb/movies?status=soon');

			this.setState({ moviesSoon: responseSoon.data, loadingSoon: false });
		} catch (error) {
			console.error('Erro ao buscar filmes:', error);
		}
	};

	// Chama a função de fetch quando o componente monta
	componentDidMount() {
		this.fetchMovies();
	}

	render() {
		const { userData } = this.props;
		const { moviesNow, moviesSoon, loadingNow, loadingSoon } = this.state;

		return (
			<Grid padded>
				<ContentHeader userData={userData} />

				<Grid.Row>
					<Grid.Column>
						<Header as="h3" block>Filmes em Exibição</Header>
					</Grid.Column>
				</Grid.Row>

				{loadingNow ? (
					<Loader active inline='centered' />
				) : (
					<Grid.Row className='movieListing'>
						{moviesNow.map((movie) => (
							<Grid.Column key={movie.api_id} mobile={8} tablet={4} computer={2}>
								<Card fluid link style={{ marginBottom: '1rem' }}>
									<Image src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} wrapped ui={false} />
								</Card>
							</Grid.Column>
						))}
					</Grid.Row>
				)}

				<Grid.Row>
					<Grid.Column>
						<Header as="h3" block>Próximos Lançamentos</Header>
					</Grid.Column>
				</Grid.Row>

				{loadingSoon ? (
					<Loader active inline='centered' />
				) : (
					<Grid.Row className='movieListing'>
						{moviesSoon.map((movie) => (
							<Grid.Column key={movie.api_id} mobile={8} tablet={4} computer={2}>
								<Card fluid link style={{ marginBottom: '1rem' }}>
									<Image src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} wrapped ui={false} />
								</Card>
							</Grid.Column>
						))}
					</Grid.Row>
				)}
			</Grid>
		);
	}
}