import React, { useState, useEffect } from 'react';
import { 
    Button, ButtonGroup, Grid, Table, List, ListItem, ListHeader, ListDescription, Popup, Label,
    Modal, ModalHeader, ModalContent, ModalDescription, ModalActions, Pagination, Icon, Input, Dimmer, Loader
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { formatDocument, formatDate } from '../../utils/functions';

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [syncing, setSyncing] = useState(false);
    const [fetchingClients, setFetchingClients] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState({});
    const [openModals, setOpenModals] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('ascending');

    // 
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    
    const indexOfLastClient = currentPage * itemsPerPage;
    const indexOfFirstClient = indexOfLastClient - itemsPerPage;
    
    // Filtra os clientes com base no termo de pesquisa
    const filteredClients = clients.filter(client => 
        (client.name && client.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (client.company_name && client.company_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (client.document && String(client.document).toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // Ordena os clientes com base na coluna da tabela
    const sortedClients = [...filteredClients].sort((a, b) => {
        if (!sortColumn) return 0;
    
        let valA = a[sortColumn];
        let valB = b[sortColumn];
    
        // Formatação e comparação específicas para cada coluna
        if (sortColumn === 'document') {
            valA = a.document.replace(/[^\d]+/g, '');  // Formatação de CPF/CNPJ
            valB = b.document.replace(/[^\d]+/g, '');
        } else if (sortColumn === 'date_updated') {
            valA = new Date(a.date_updated);
            valB = new Date(b.date_updated);
        } else if (sortColumn === 'diff') {
            // Comparar diretamente como strings
            valA = String(a.diff || '');  // Garantir que esteja como string
            valB = String(b.diff || '');
        }
    
        if (valA < valB) return sortDirection === 'ascending' ? -1 : 1;
        if (valA > valB) return sortDirection === 'ascending' ? 1 : -1;
        return 0;
    });
    

    const pageNumbers = [];
    const totalPages = Math.ceil(filteredClients.length / itemsPerPage);
    
    // Calcula as páginas a exibir com base na página atual
    let startPage = Math.max(currentPage - 2, 1);  // Não permite que a página inicial seja menor que 1
    let endPage = Math.min(currentPage + 2, totalPages);  // Não permite que a última página seja maior que o total de páginas
    
    // Garante que se a página inicial for muito próxima do início, as páginas não excedam o número total
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }    

    useEffect(() => {
        fetchClients();
    }, []);

    /**
     * Manipula a ordenação das colunas da tabela.
     * Alterna a direção da ordenação ou define uma nova coluna para ordenar.
     *
     * @param {*} column Nome da coluna a ser ordenada.
     */
    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(column);
            setSortDirection('ascending');
        }
    };

    /**
     * Busca a lista de clientes na API da Conta Azul e atualiza o estado da aplicação.
     */
    const fetchClients = async () => {
        setFetchingClients(true);

        try {
            const response = await window.cs_get('/contaazul/customers');

            // Atualiza o estado com os clientes recebidos
            setClients(response.data);
        } catch (error) {
            console.error('Erro ao obter os customers:', error);

            // Exibe um alerta para o usuário
            toast.error('Erro ao obter os clientes. Por favor, verifique o console.');
        } finally {
            setFetchingClients(false);
        }
    };

    /**
     * Sincroniza a lista de clientes com a API da Conta Azul.
     */
    const syncClients = async () => {
        setSyncing(true);

        try {
            await window.cs_get('/contaazul/getCustomers');

            toast.success('Clientes do Conta Azul sincronizados com sucesso!');
        } catch (error) {
            console.error('Erro ao sincronizar os customers:', error);

            toast.error('Erro ao sincronizar os clientes do Conta Azul. Por favor, verifique o console.');
        } finally {
            setSyncing(false);

            fetchClients();
        }
    };

    /**
     * Sincroniza um cliente específico com a API da Conta Azul, permitindo criar, baixar ou atualizar o cadastro.
     *
     * @async
     * @function clientSync
     * @param {*} clientID ID do cliente a ser sincronizado.
     * @param {*} action Ação a ser realizada: 'insert' (criação), 'download' (baixar dados do Conta Azul) ou 'upload' (atualizar no Conta Azul).
     * @returns {Promise<void>} Nenhum valor é retornado diretamente.
     */
    const clientSync = async (clientID, action) => {
        let confirmMessage = '';
        let successMessage = '';
        let errorMessage = '';

        if(action === 'insert') {
            confirmMessage = 'Tem certeza que deseja criar o cadastro desse cliente na plataforma?';
            successMessage = 'Cliente cadastrado na plataforma com sucesso!';
            errorMessage   = 'Erro ao cadastrar o cliente na plataforma. Erro: ';
        }
        else if(action === 'download') {
            confirmMessage = 'Tem certeza que deseja baixar o cadastro do Conta Azul desse cliente?';
            successMessage = 'O cadastro do cliente foi atualizado com sucesso com os dados da Conta Azul.';
            errorMessage   = 'Erro ao atualizar o cadastro do cliente com os dados da Conta Azul. Erro: ';
        }
        else if(action === 'upload') {
            confirmMessage = 'Tem certeza que deseja atualizar o cadastro desse cliente no Conta Azul?';
            successMessage = 'O cadastro do cliente foi atualizado com sucesso no Conta Azul.';
            errorMessage   = 'Erro ao atualizar o cadastro do cliente no Conta Azul. Erro: ';
        }

        if (window.confirm(confirmMessage)) {
            setDisabledButtons(prev => ({ ...prev, [clientID]: true }));

            try {
                await window.cs_get('/contaazul/sync/' + clientID + '/' + action);
                
                toast.success(successMessage);
            } catch (error) {
                console.error('Erro no ' + action + ' do cliente:', error);
                
                toast.error(errorMessage + error);
            } finally {
                setDisabledButtons(prev => ({ ...prev, [clientID]: false }));

                fetchClients();
            }
        }
    };

    /**
     * Formata uma string de diferença (diff) para exibição como uma lista.
     *
     * @param {*} diff Texto contendo as diferenças entre os cadastros.
     * @returns JSX contendo a lista formatada ou null se não houver diff.
     */
    const formatDiff = (diff) => {
        if (!diff) {
            return null;
        }
        const lines = diff.split('\n').filter(line => line.trim() !== '');
        return (
            <List>
                {lines.map((line, index) => {
                    const match = line.match(/-(.*) \(Cadastro = "(.*)" \| ContaAzul = "(.*)"\)/);
                    if (!match) return null;
                    const label = match[1].trim();
                    const cadastro = match[2].trim();
                    const contaAzul = match[3].trim();
                    return (
                        <ListItem key={index}>
                            <ListHeader>{label}</ListHeader>
                            <ListDescription><small>Cadastro = "{cadastro}" | ContaAzul = "{contaAzul}"</small></ListDescription>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    /**
     * Abre o modal para um cliente específico.
     *
     * @param {*} clientId ID do cliente para o qual o modal será aberto.
     */
    const handleOpenModal = (clientId) => {
        setOpenModals(prev => ({ ...prev, [clientId]: true }));
    };
    
    /**
     * Fecha o modal para um cliente específico.
     * @param {*} clientId ID do cliente para o qual o modal será fechado.
     */
    const handleCloseModal = (clientId) => {
        setOpenModals(prev => ({ ...prev, [clientId]: false }));
    };

    return (
        <>
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    {fetchingClients && <Dimmer active inverted><Loader inverted><strong>Carregando...</strong></Loader></Dimmer>}

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Input 
                                icon='search' 
                                placeholder='Pesquisar...' 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Button primary onClick={syncClients} disabled={syncing}>
                            {syncing ? 'Sincronizando...' : 'Sincronizar Conta Azul'}
                        </Button>
                    </div>

                    <Table sortable striped compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell sorted={sortColumn === 'id' ? sortDirection : null} onClick={() => handleSort('id')}>ID</Table.HeaderCell>
                                <Table.HeaderCell sorted={sortColumn === 'name' ? sortDirection : null} onClick={() => handleSort('name')}>Nome</Table.HeaderCell>
                                <Table.HeaderCell sorted={sortColumn === 'document' ? sortDirection : null} onClick={() => handleSort('document')}>CPF/CNPJ</Table.HeaderCell>
                                <Table.HeaderCell sorted={sortColumn === 'sync' ? sortDirection : null} onClick={() => handleSort('sync')}>Cadastrado</Table.HeaderCell>
                                <Table.HeaderCell sorted={sortColumn === 'diff' ? sortDirection : null} onClick={() => handleSort('diff')}>Diferenças</Table.HeaderCell>
                                <Table.HeaderCell sorted={sortColumn === 'date_updated' ? sortDirection : null} onClick={() => handleSort('date_updated')}>Última Atualização</Table.HeaderCell>
                                <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {!fetchingClients && clients.length === 0 && <Table.Row><Table.Cell colSpan="6" textAlign="center"><strong>Nenhum cliente encontrado</strong></Table.Cell></Table.Row>}
                            {sortedClients.slice(indexOfFirstClient, indexOfLastClient).map(client => (
                                <Table.Row key={client.id} negative={!client.sync}>
                                    <Table.Cell>{client.id}</Table.Cell>
                                    <Table.Cell><b>{client.name || '?'}</b><br /><small>{client.company_name || ''}</small></Table.Cell>
                                    <Table.Cell><b><small>{(client.person_type === "LEGAL" ? 'Jurídica' : 'Física')}</small></b><br />{formatDocument(client.document)}</Table.Cell>
                                    <Table.Cell><Label size='small' color={(client.sync ? 'green' : 'red')}>{(client.sync ? 'Sim' : 'Não')}</Label></Table.Cell>
                                    <Table.Cell>{formatDiff(client.diff)}</Table.Cell>
                                    <Table.Cell width="3">{formatDate(client.date_updated)}</Table.Cell>
                                    <Table.Cell width="2" textAlign="right">
                                        <ButtonGroup>
                                            <Modal
                                                onClose={() => handleCloseModal(client.id)}
                                                onOpen={() => handleOpenModal(client.id)}
                                                open={openModals[client.id] || false}
                                                trigger={<Button size='small' icon='eye' />}
                                            >
                                                <ModalHeader>{client.name}</ModalHeader>
                                                <ModalContent>
                                                    <ModalDescription>
                                                        <Table definition>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Nome</b></Table.Cell>
                                                                    <Table.Cell>{client.name}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Nome da Empresa</b></Table.Cell>
                                                                    <Table.Cell>{client.company_name}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Email</b></Table.Cell>
                                                                    <Table.Cell>{client.email}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Telefone Comercial</b></Table.Cell>
                                                                    <Table.Cell>{client.business_phone}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Telefone Celular</b></Table.Cell>
                                                                    <Table.Cell>{client.mobile_phone}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Tipo de Pessoa</b></Table.Cell>
                                                                    <Table.Cell>{client.person_type}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Documento</b></Table.Cell>
                                                                    <Table.Cell>{formatDocument(client.document)}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Documento de Identidade</b></Table.Cell>
                                                                    <Table.Cell>{client.identity_document}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Número de Inscrição Estadual</b></Table.Cell>
                                                                    <Table.Cell>{client.state_registration_number}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Tipo de Inscrição Estadual</b></Table.Cell>
                                                                    <Table.Cell>{client.state_registration_type}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Número de Inscrição Municipal</b></Table.Cell>
                                                                    <Table.Cell>{client.city_registration_number}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Data de Nascimento</b></Table.Cell>
                                                                    <Table.Cell>{client.date_of_birth}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Endereço</b></Table.Cell>
                                                                    <Table.Cell>
                                                                        {Object.entries(JSON.parse(client.address)).map(([key, value]) => (
                                                                            <div key={key}>
                                                                                {key}: {typeof value === "object" && value !== null ? `{ name: ${value.name} }` : value}
                                                                            </div>
                                                                        ))}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Notas</b></Table.Cell>
                                                                    <Table.Cell>{client.notes}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Criação no ContaAzul</b></Table.Cell>
                                                                    <Table.Cell>{formatDate(client.created_at)}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>Data de Atualização</b></Table.Cell>
                                                                    <Table.Cell>{formatDate(client.date_updated)}</Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><b>ContaAzul ID</b></Table.Cell>
                                                                    <Table.Cell>{client.api_id}</Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </ModalDescription>
                                                </ModalContent>
                                                <ModalActions style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button color='black' onClick={() => handleCloseModal(client.id)}>Fechar</Button>
                                                </ModalActions>
                                            </Modal>

                                            { !client.sync && (
                                                <Popup size='small' inverted hideOnScroll position='top right' mouseEnterDelay={0} mouseLeaveDelay={0} content='Criar Cadastro' trigger={
                                                    <Button color='purple' size='small' icon='arrow down' onClick={() => clientSync(client.id, 'insert')} disabled={disabledButtons[client.id]} loading={disabledButtons[client.id]} />
                                                } />
                                            )}

                                            {client.diff && client.diff.trim() !== '' && (
                                                <>
                                                    <Popup size='small' inverted hideOnScroll position='top right' mouseEnterDelay={0} mouseLeaveDelay={0} content='Atualizar Cadastro' trigger={
                                                        <Button color='black' size='small' icon='arrow down' onClick={() => clientSync(client.id, 'download')} disabled={disabledButtons[client.id]} loading={disabledButtons[client.id]} />
                                                    } />
                                                    <Popup size='small' inverted hideOnScroll position='top right' mouseEnterDelay={0} mouseLeaveDelay={0} content='Atualizar Conta Azul' trigger={
                                                        <Button color='blue' size='small' icon='arrow up' onClick={() => clientSync(client.id, 'upload')} disabled={disabledButtons[client.id]} loading={disabledButtons[client.id]} />
                                                    } />
                                                </>
                                            )}
                                        </ButtonGroup>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    {!fetchingClients && clients.length >= 1 && (
                        <>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <Pagination
                                boundaryRange='1'
                                siblingRange='2'
                                firstItem={null}
                                lastItem={null}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                activePage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
                            />
                        </div>
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </>
    );
};

export default Clients;