import React, { useState, useEffect } from 'react';
import { Table, Grid, Dimmer, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { formatDate, resumeText } from '../../utils/functions';

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {
            setLoadingLogs(true);

            try {
                const response = await window.cs_get('/contaazul/logs');

                setLogs(response.data);
            } catch (error) {
                console.error('Erro ao obter os logs:', error);

                toast.error('Erro ao obter os logs. Por favor, verifique o console.');
            } finally {
                setLoadingLogs(false);
            }
        };

        fetchLogs();
    }, []);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {loadingLogs && <Dimmer active inverted><Loader inverted><strong>Carregando...</strong></Loader></Dimmer>}

                    <Table striped compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ID</Table.HeaderCell>
                                <Table.HeaderCell>URL</Table.HeaderCell>
                                <Table.HeaderCell>Método</Table.HeaderCell>
                                <Table.HeaderCell>Resposta</Table.HeaderCell>
                                <Table.HeaderCell>Data e Hora</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {logs.map(log => (
                                <Table.Row key={log.id}>
                                    <Table.Cell>{log.id}</Table.Cell>
                                    <Table.Cell>{log.url}</Table.Cell>
                                    <Table.Cell>{log.method}</Table.Cell>
                                    <Table.Cell>{resumeText(String(log.response), 80)}</Table.Cell>
                                    <Table.Cell>{formatDate(log.timestamp)}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default Logs;