import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Grid, Tab, Menu, Button, Select, Message, Modal, Table, Header, List, Label, Popup, Icon } from 'semantic-ui-react';
import ContentHeader from '../../components/ContentHeader';

import DropzoneComponent from 'react-dropzone-component';

import moment from 'moment';
import localforage from 'localforage';

import { formatCurrency, formatOurNumber } from 'unid-core/lib/utils/formatter';
import { capitalizeFirstLetter, escape } from 'unid-core/lib/utils/string';
import { ProcessReturn, GetOcurrenceInfo, GetOcurrenceDescription } from 'unid-core/lib/sicred/return';

import { getCutsInfo, getNfText } from '../utils/nfse';

import { toast } from 'react-toastify';

import './FinancialAdmin.css';

class DetailInfo extends Component {
  state = {
    autoNfses: [],
    generateNfseModal: false,
    generatingNfses: false,
    detailInfo: undefined
  }

  async componentDidMount() {
    const identification = Number(this.props.detail.identification) - 1000000000;
    if (Number.isNaN(identification)) return;

    try {
      const res = await window.cs_get(`/tickets/${identification}/info`);
      this.setState({ detailInfo: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  ocurrenceColor(ocurrence) {
    if (ocurrence === '02' || ocurrence === '06' || ocurrence === '15' || ocurrence === '17' || ocurrence === '28')
      return '#008000';
    else
      return '#800000';
  }

  render() {
    const d = this.props.detail;

    let additionalText = '';
    if (d.ocurrence === '06') additionalText = ` - ${formatCurrency(d.paidValue)}`;
    if (d.ocurrence === '28') additionalText = ` - ${formatCurrency(d.expenseValue + d.protestExpenseValue)}`;

    return (
      <div style={{padding:'10px 0',borderTop:'1px solid #ddd',color:this.ocurrenceColor(d.ocurrence)}}>
        <p style={{margin:0}}>
          { this.state.detailInfo && <Link style={{marginRight:6}} target='_blank' to={`/pedidos/${this.state.detailInfo.order_id}`}><b>#{this.state.detailInfo.order_id}</b></Link> }
          <b>{formatOurNumber(d.ourNumber)}</b>
          { this.state.detailInfo && <span style={{margin:'0 6px'}}>{this.state.detailInfo.entity_name}</span> }
          | {formatCurrency(d.price)} - {d.dueDate.format('DD/MM/YYYY')}
        </p>
        <p style={{margin:'2px 0 0 0'}}>{ GetOcurrenceDescription(d.ocurrence) }{ additionalText }</p>
        { d.ocurrenceInfo.map((oi, k) => {
          if (oi !== '00')
            return (
              <p key={k} style={{margin:'4px 0 0 10px'}}>- { GetOcurrenceInfo(d.ocurrence, oi) }</p>
            );
          else return null;
        })}
      </div>
    );
  }
}

export default class FinancialAdmin extends Component {
  state = {
    payments: undefined,
    paymentsMonth: moment().format('YYYY-MM'),
    shippings: undefined,
    tickets: undefined,
    returns: undefined,
    logs: undefined,
    return: undefined
  };

  // 2 lugares, caso alteração, pesquise e altere em ambos locais
  isNewBoleto(number) {
    return number.toString().startsWith('18');
  }

  paymentsList() {
    if (!this.state.payments) return [];

    let ret = [];
    for (let payment of this.state.payments) {
      let nfseCount = 0;
      let ticketCount = 0;

      ret.push(
        <Table.Row key={`${payment.order_id}_${payment.idx}`}>
          <Table.Cell><Link to={`/pedidos/${payment.order_id}`}>#{payment.order_id}</Link></Table.Cell>
          <Table.Cell>{payment.idx+1} de {payment.installments_count}</Table.Cell>
          <Table.Cell>{payment.name}</Table.Cell>
          <Table.Cell>{moment(payment.date).format('DD/MM/YYYY')}</Table.Cell>
          <Table.Cell>{formatCurrency(payment.price)}</Table.Cell>
          <Table.Cell>
            { payment.nfses.map((e, j) => {
              nfseCount++;
              return (
                <p key={j} style={{margin:nfseCount>1?'5px 0 0 0':'0'}}>
                  nº {e.number}
                  <a href={`https://maringa.fintel.com.br/ImprimirNfse/${e.number}/21923681000130/${e.verification_code}`} target='_blank' rel='noopener noreferrer'><Button style={{marginLeft:5}} primary icon='eye' /></a>
                </p>
              );
            }) }

            { payment.nfses.length === 0 && payment.auto_nfe !== 1 && <p>-</p> }
            { payment.nfses.length === 0 && payment.auto_nfe === 1 && <Popup inverted position='top center' style={{ textAlign: 'center' }} trigger={<div>Automático <Icon name='info circle' color='orange' size='large' /></div>}>A nota fiscal deste PI pode ser gerada automaticamente.</Popup> }
          </Table.Cell>
          <Table.Cell>
            { payment.tickets.map((e, j) => {
              ticketCount++;

              const isLate = moment().isAfter(moment(e.date).endOf('day'));

              return (
                <p key={j} style={{margin:ticketCount>1?'5px 0 0 0':'0'}}>
                  { !isLate && e.status === 1 && <Popup position='top center' trigger={<Icon name='help circle' color='grey' />} content='Aguardando confirmação bancária' /> }
                  { !isLate && e.status === 2 && <Popup position='top center' trigger={<Icon name='exclamation circle' color='yellow' />} content='A vencer' /> }
                  { e.status === 3 && <Popup position='top center' size='mini' trigger={<Icon name='check circle' color='olive' />} content='Pago' /> }
                  { isLate && e.status !== 3 && <Popup position='top center' trigger={<Icon name='remove circle' color='red' />} content='Vencido' /> }
                  { e.status === 10 && <Popup position='top center' trigger={<Icon name='exclamation circle' color='yellow' />} content='Aguardando remoção' /> }
                  { e.status === 11 && <Popup position='top center' trigger={<Icon name='help circle' color='blue' />} content='Aguardando remoção bancária' /> }

                  <span style={{color:'#999'}}>
                    { this.isNewBoleto(e.number) && `${formatOurNumber(e.number)}`}
                    { !this.isNewBoleto(e.number) && `${e.number}`}
                  </span>
                  <span style={{marginLeft:6,color:'#589945',fontWeight:600}}>{ formatCurrency(e.value) }</span>
                  { e.barcode && <a href={`/tickets/${e.id}/${e.barcode}`} target='_blank' rel='noopener noreferrer'><Button style={{marginLeft:5}} primary icon='eye' /></a> }
                  { !e.barcode && <Button disabled style={{marginLeft:5}} primary icon='eye' /> }
                </p>
              );
            })}
            { payment.tickets.length === 0 && <p>-</p> }
          </Table.Cell>
        </Table.Row>
      );
    }

    return ret;
  }

  async listPayments() {
    this.setState({ payments: undefined });

    try {
      const res = await window.cs_get('/orders/payments', { month: this.state.paymentsMonth });
      this.setState({ payments: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async listShippings() {
    this.setState({ shippings: undefined });

    try {
      const res = await window.cs_get('/tickets/shippings');
      this.setState({ shippings: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async listReturns() {
    this.setState({ returns: undefined });

    try {
      const res = await window.cs_get('/tickets/returns');
      this.setState({ returns: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async listTickets() {
    this.setState({ tickets: undefined });

    try {
      const res = await window.cs_get('/tickets/waiting-shipping');
      this.setState({ tickets: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async listLogs() {
    this.setState({ logs: undefined });
  
    try {
      const res = await window.cs_get('/nfse/logs');
      this.setState({ logs: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async componentDidMount() {
    this.userid = await localforage.getItem('user_id');
    this.token = await localforage.getItem('token');

    this.listPayments();
    this.listShippings();
    this.listTickets();
    this.listReturns();
    this.listLogs();
  }

  months() {
    let ret = [];
    let date = moment('2016-01-01');

    while(date.isBefore(moment().add(1, 'year'))) {
      const dateStr = date.format('YYYY-MM');
      ret.push({ key: dateStr, value: dateStr, text: capitalizeFirstLetter(date.format('MMMM [de] YYYY')) });
      date.add(1, 'month');
    }

    return ret;
  }

  total() {
    let ret = 0;
    for (let payment of this.state.payments) ret += payment.price;
    return ret;
  }

  totalPaid() {
    let ret = 0;

    for (let payment of this.state.payments) {
      for (let ticket of payment.tickets)
        ret += ticket.status === 3 ? ticket.value : 0;
    }

    return ret;
  }

  async viewReturn(id) {
    let same = this.state.return ? this.state.return.id === id : false;
    this.setState({ return: undefined });
    if (same) return;

    try {
      const res = await window.cs_get(`/tickets/returns/${id}`);
      this.setState({ return: res.data });
    } catch (err) {
      console.error(err);
    }
  }

  async showGenerateNfModal() {
    const autoNfses = this.state.payments.filter((e) => e.nfses.length === 0 && e.auto_nfe === 1);
    this.setState({ generateNfseModal: true, autoNfses });
  }

  async generateNf() {
    const autoNfses = this.state.payments.filter((e) => e.nfses.length === 0 && e.auto_nfe === 1);
    this.setState({ generateNfseModal: true, generatingNfses: true, autoNfses });

    // fetch taxes
    let taxes = { municipalTax: '', federalTax: '' };

    try {
      const vars = await window.cs_get('/vars');
      
      for (const row of vars.data) {
        if (row.name === 'municipal_nfe_tax') taxes.municipalTax = row.value;
        if (row.name === 'federal_nfe_tax') taxes.federalTax = row.value;
      }
    } catch {
      // do nothing
    }

    for (let i = 0; i < autoNfses.length; i++) {
      autoNfses[i].status = 'processing';
      this.setState({ autoNfses })

      const nfe = autoNfses[i];

      try {
        const order = (await window.cs_get(`/orders/${nfe.order_id}`, { full: true })).data;
        const nfses = (await window.cs_get(`/nfse/${nfe.order_id}`)).data;

        const cuts = getCutsInfo(order, nfses, nfe.idx, nfe.price, 0);
        const installmentCut = getCutsInfo(order, nfses, nfe.idx, nfe.price, cuts[nfe.idx].networksCut, true)[nfe.idx];

        const nfText = getNfText(order, nfses, taxes, nfe.idx, nfe.price, cuts[nfe.idx].networksCut);
        
        const { number, verification_code } = (await window.cs_post('/nfse', {
          order: nfe.order_id,
          installment: nfe.idx,
          entity: order.advertiser_id,
          value: nfe.price,
          noCut: false,
          agencyCut: installmentCut.agencyCut,
          networksCut: installmentCut.networksCut,
          text: nfText,
          type: '1008'
        })).data;

        autoNfses[i].status = 'ok';
        autoNfses[i].return = { number, verification_code };

        this.setState({ autoNfses })
      } catch (error) {
        autoNfses[i].status = 'failed';
        this.setState({ autoNfses })

        toast.error(error);
      }
    }

    this.setState({ generatingNfses: false });
    this.listPayments();
  }

  render() {
    const djsConfig = {
      timeout: 0,
      maxFilesize: 10,
      addRemoveLinks : true,
      autoProcessQueue: true,
      dictDefaultMessage: '<h1>Clique ou arraste o arquivo de retorno aqui para fazer upload.<p>Não envie vários arquivos pois poderá ocorrer problemas, sempre envie em ordem.</p>',
      dictResponseError: 'Erro ao tentar fazer upload',
      dictFallbackText: 'Envie os arquivos abaixo.',
      dictFileTooBig: 'O arquivo é muito grande. Tamanho máximo: 10 MB.',
      dictInvalidFileType: 'Você nao pode enviar arquivos desse tipo.',
      dictCancelUpload: 'Cancelar upload',
      dictCancelUploadConfirmation: 'Você tem certeza que deseja cancelar esse upload?',
      dictRemoveFile: 'Remover da lista',
      dictRemoveFileConfirmation: null,
      dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.'
    };

    const dropzoneConfig = {
      postUrl: process.env.NODE_ENV === 'production' ? window.location.origin+'/tickets/returns' : 'http://localhost:3080/tickets/returns'
    };

    const eventHandlers = {
      sending: (file, xhr, formData) => {
        xhr.setRequestHeader('x-userid', this.userid);
        xhr.setRequestHeader('x-token', this.token);
      },
      complete: (file) => {
        this.listReturns();
      }
    };

    const ret = this.state.return ? ProcessReturn(this.state.return.text.split('\r\n')) : null;

    const panes = [
      {
        menuItem: <Menu.Item key={1}>Vencimentos</Menu.Item>,
        render: () => (
          <Tab.Pane loading={!this.state.payments}>
            <Grid>
              <Grid.Row>
                <Grid.Column style={{ width: 'unset', flex: 1 }}>
                  <Select value={this.state.paymentsMonth} options={this.months()} onChange={(e, { value }) => this.setState({ paymentsMonth: value }, () => this.listPayments()) } />
                </Grid.Column>

                <Grid.Column style={{ width: 'unset' }}>
                  <Popup inverted position='top center' style={{ textAlign: 'center' }} trigger={<Button onClick={() => this.showGenerateNfModal()} color='blue' icon='sync' content='Gerar todas NF-e' />}>Clique para gerar todas as notas fiscais automáticas.</Popup>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  { this.state.payments && this.state.payments.length > 0 &&
                    <Table basic style={{fontSize:12}}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>PI</Table.HeaderCell>
                          <Table.HeaderCell>Parcela</Table.HeaderCell>
                          <Table.HeaderCell>Cliente</Table.HeaderCell>
                          <Table.HeaderCell>Vencimento</Table.HeaderCell>
                          <Table.HeaderCell>Valor</Table.HeaderCell>
                          <Table.HeaderCell>NFS-e</Table.HeaderCell>
                          <Table.HeaderCell>Boleto</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        { this.paymentsList() }
                      </Table.Body>
                    </Table>
                  }
                  { this.state.payments && this.state.payments.length > 0 &&
                    <Header size='small' color='olive' floated='right'>
                      <span style={{color:'#000'}}>Total:</span> {formatCurrency(this.total())}
                      <span style={{color:'#000',marginLeft:16}}>Pago:</span> {formatCurrency(this.totalPaid())}
                      <span style={{color:'#000',marginLeft:16}}>Pendente:</span> {formatCurrency(this.total() - this.totalPaid())}
                    </Header>
                  }
                  { this.state.payments && this.state.payments.length === 0 &&
                    <Message warning>
                      <Message.Header>Nenhum pagamento encontrado.</Message.Header>
                    </Message>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={2}>Remessa{ this.state.tickets && this.state.tickets.length > 0 && <Label>{this.state.tickets.length}</Label> }</Menu.Item>,
        render: () => (
          <Tab.Pane loading={false}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  { this.state.tickets && this.state.tickets.length > 0 &&
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Pedido</Table.HeaderCell>
                          <Table.HeaderCell>Cliente</Table.HeaderCell>
                          <Table.HeaderCell>Valor</Table.HeaderCell>
                          <Table.HeaderCell>Vencimento</Table.HeaderCell>
                          <Table.HeaderCell>Boleto</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        { this.state.tickets && this.state.tickets.map((e, i) => {
                          return (
                            <Table.Row key={e.id}>
                              <Table.Cell style={{ fontFamily: 'Cutive Mono', fontWeight: 600, fontSize: 15 }}><a href={`/pedidos/${e.order_id}`} target='_blank' rel='noopener noreferrer'>#{e.order_id}</a></Table.Cell>
                              <Table.Cell>{ e.entity_name }</Table.Cell>
                              <Table.Cell>{ e.status===10?'-':formatCurrency(e.value) } </Table.Cell>
                              <Table.Cell>{ e.status===10?'-':moment(e.date).format('DD/MM/YYYY') }</Table.Cell>
                              <Table.Cell style={{ fontFamily: 'Cutive Mono', fontWeight: 600, fontSize: 15 }}><a href={`/tickets/${e.id}/${e.barcode}`} target='_blank' rel='noopener noreferrer'>{`${e.number.slice(0, 2)}/${e.number.slice(2, 8)}-${e.number.slice(8, 9)}` }</a></Table.Cell>
                            </Table.Row>
                          );
                        })}
                      </Table.Body>
                    </Table>
                  }

                  { this.state.tickets && this.state.tickets.length > 0 &&
                    <div style={{textAlign:'right'}}>
                      <a href={`/tickets/shipping?userid=${this.userid}&token=${escape(this.token)}`} target='_blank' rel='noopener noreferrer'><Button color='blue' content='Gerar remessa' icon='download' /></a>
                    </div>
                  }

                  <List relaxed divided style={{marginTop: this.state.tickets && this.state.tickets.length > 0 ? 32 : 0 }}>
                    { this.state.shippings && this.state.shippings.map((e, i) => {
                      return (
                        <List.Item key={e.id}>
                          <List.Content floated='right'>
                            <a href={`/tickets/shipping/${e.id}/${e.name}`} target='_blank' rel="noopener noreferrer"><Button color='blue' size='tiny' icon='download' /></a>
                          </List.Content>

                          <List.Content>
                            <List.Header>{ e.name }</List.Header>
                            <List.Description style={{fontSize:12}}>{ moment(e.date).format('DD/MM/YYYY') }</List.Description>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={3}>Retorno</Menu.Item>,
        render: () => (
          <Tab.Pane loading={false}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                <DropzoneComponent ref='dz' config={dropzoneConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} />

                  <List relaxed divided>
                    { this.state.returns && this.state.returns.map((e, i) => {
                      return (
                        <List.Item key={e.id}>
                          <List.Content floated='right'>
                            <Button color='blue' size='tiny' icon='eye' onClick={()=>this.viewReturn(e.id)} />
                          </List.Content>

                          <List.Content>
                            <List.Header>{ e.name }</List.Header>
                            <List.Description style={{fontSize:12}}>{ moment(e.date).format('DD/MM/YYYY') }</List.Description>
                            { ret && this.state.return && this.state.return.id === e.id &&
                              <div style={{margin:'12px 0 0 24px'}}>
                                { ret.details.map((d, j) => {
                                  return <DetailInfo key={j} detail={d} />
                                })}
                              </div>
                            }
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      },
      {
        menuItem: <Menu.Item key={4}>Logs</Menu.Item>,
        render: () => (
          <Tab.Pane loading={!this.state.logs}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  {this.state.logs && this.state.logs.length > 0 ? (
                    <Table basic style={{ fontSize: 12 }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>ID</Table.HeaderCell>
                          <Table.HeaderCell>Entity ID</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Mensagem</Table.HeaderCell>
                          <Table.HeaderCell>Data de Criação</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.logs.map((log, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{log.id}</Table.Cell>
                            <Table.Cell>{log.entity_id}</Table.Cell>
                            <Table.Cell>
                              <Label color={log.status === 'success' ? 'green' : 'red'}>
                                {log.status === 'success' ? 'Sucesso' : 'Erro'}
                              </Label>
                            </Table.Cell>
                            <Table.Cell>{log.message}</Table.Cell>
                            <Table.Cell>{moment(log.created_at).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  ) : (
                    <Message warning>
                      <Message.Header>Nenhum registro de log encontrado.</Message.Header>
                    </Message>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      }
    ];

    return (
      <>
      <Modal open={this.state.generateNfseModal} size='small'>
        <Modal.Header>Gerar notas fiscais automaticamente</Modal.Header>
       
        <Modal.Content>
          {this.state.autoNfses && <>
            {this.state.autoNfses.length === 0 &&
              <Message warning>
                <Message.Header>Nenhuma nota para gerar.</Message.Header>
                <p>Não existem notas automáticas pendentes para geração.</p>
              </Message>
            }

            {this.state.autoNfses.length !== 0 &&
              <>
                {this.state.generatingNfses &&
                  <Message warning>
                    <Message.Header>Por favor, aguarde!</Message.Header>
                    <p>Não feche essa tela até que o sistema finalize o processamento de todas as notas fiscais automáticas.</p>
                  </Message>
                }

                <Table basic style={{fontSize:12}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>PI</Table.HeaderCell>
                      <Table.HeaderCell>Parcela</Table.HeaderCell>
                      <Table.HeaderCell>Cliente</Table.HeaderCell>
                      <Table.HeaderCell>Vencimento</Table.HeaderCell>
                      <Table.HeaderCell>Valor</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.autoNfses.map((e) => (
                      <Table.Row key={e.order_id}>
                        <Table.Cell>#{e.order_id}</Table.Cell>
                        <Table.Cell>{e.idx+1} de {e.installments_count}</Table.Cell>
                        <Table.Cell>{e.name}</Table.Cell>
                        <Table.Cell>{moment(e.date).format('DD/MM/YYYY')}</Table.Cell>
                        <Table.Cell>{formatCurrency(e.price)}</Table.Cell>
                        {!e.status && <Table.Cell><Icon name='clock outline' color='orange' /> Aguardando</Table.Cell>}
                        {e.status === 'processing' && <Table.Cell><Icon name='hourglass outline' color='blue' /> Processando...</Table.Cell>}
                        {e.status === 'ok' && <Table.Cell><Icon name='check' color='olive' /> Concluído! <a href={`https://maringa.fintel.com.br/ImprimirNfse/${e.return.number}/21923681000130/${e.return.verification_code}`} target='_blank' rel='noopener noreferrer'>(NF-e {e.return.number})</a></Table.Cell>}
                        {e.status === 'failed' && <Table.Cell><Icon name='remove circle' color='red' /> Falha!</Table.Cell>}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </>
            }
          </>}
        </Modal.Content>

        <Modal.Actions>
          <Button size='small' loading={this.state.generatingNfses} disabled={this.state.generatingNfses} content='Fechar' onClick={()=>this.setState({generateNfseModal:false})} />
          { !this.state.generatingNfses && (this.state.autoNfses||[]).length > 0 && <Button size='small' primary content='Gerar notas fiscais' onClick={()=>this.generateNf()} /> }
        </Modal.Actions>
      </Modal>

      <Grid padded className='FinancialAdmin'>
        <ContentHeader userData={this.props.userData} path={['Financeiro']}/>

        <Grid.Row className='content-margin'>
          <Grid.Column>
            <Tab panes={panes} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </>
    );
  }
}
